// public/js/dotMatrixMobile.js

const canvas = document.getElementById("dotMatrixCanvas");
const ctx = canvas.getContext("2d");

canvas.width = window.innerWidth;
canvas.height = window.innerHeight;

const numColumns = 50;
const dotSize = 10;
const fallSpeed = 2;
const dotTransparency = 0.3;
const dotColor = `rgba(28, 28, 28, ${dotTransparency})`;

class Dot {
  constructor(x, y) {
    this.x = x;
    this.y = y;
  }

  update() {
    this.y += fallSpeed;

    if (this.y > canvas.height) {
      this.y = -dotSize;
    }
  }

  draw() {
    const h1BoundingBox = getH1BoundingBox();

    if (
      this.x >= h1BoundingBox.x &&
      this.x <= h1BoundingBox.x + h1BoundingBox.width &&
      this.y >= h1BoundingBox.y - dotSize / 2 &&
      this.y <= h1BoundingBox.y + h1BoundingBox.height
    ) {
      return;
    }

    ctx.beginPath();
    ctx.fillStyle = dotColor;
    ctx.arc(this.x + dotSize / 2, this.y + dotSize / 2, dotSize / 2, 0, Math.PI * 2, true);
    ctx.closePath();
    ctx.fill();
  }
}

function getH1BoundingBox() {
  const h1Element = document.querySelector("h1");
  const canvasRect = canvas.getBoundingClientRect();
  const h1Rect = h1Element.getBoundingClientRect();

  const h1BoundingBox = {
    x: h1Rect.left - canvasRect.left,
    y: h1Rect.top - canvasRect.top,
    width: h1Rect.width,
    height: h1Rect.height
  };

  return h1BoundingBox;
}

const dots = [];

function createDots() {
  const columnWidth = canvas.width / numColumns;

  for (let i = 0; i < numColumns; i++) {
    const x = i * columnWidth;
    const y = Math.random() * canvas.height;
    dots.push(new Dot(x, y));
  }
}

function updateDots() {
  for (const dot of dots) {
    dot.update();
  }
}

function drawDots() {
  for (const dot of dots) {
    dot.draw();
  }
}

function animate() {
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  updateDots();
  drawDots();
  requestAnimationFrame(animate);
}

createDots();
animate();

window.addEventListener("resize", () => {
  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;

  dots.length = 0;
  createDots();
});
